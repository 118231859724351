import Login from '../components/login/login'

import Link from 'next/link'
import Image from 'next/image'
import Head from 'next/head'

import { useSelector } from 'react-redux';

function LoginPage({ menu, config }) {
    const user = useSelector((state) => state.user);
    const links = true;

    var token = false
    if (user[0] != undefined) {
        token = user[0].token
    }

    return (
        <>
            <Head>
                <title>Sign In / Create Account</title>
            </Head>

            {(typeof token == 'undefined' || !token) ? (
                <>
                    <div className="wrapper min-h-screen flex flex-col items-center py-10 bg-ttd-sand-200">
                        <header className="header flex-shrink-0 text-center mb-10">
                            <Link href="/">
                                <a>
                                    <Image
                                        src={process.env.NEXT_PUBLIC_LOGO_IMAGE}
                                        alt={config.company_name + " Logo"}
                                        width="266"
                                        height="68"
                                        className=""
                                        priority
                                        placeholder="blur"
                                        blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                    />
                                </a>
                            </Link>
                        </header>
                        <section className="content w-full flex flex-grow flex-col">
                            <div className="container mx-auto w-full flex flex-col items-center lg:px-0 px-5">
                                <Login to="back" links={links}></Login>
                            </div>
                        </section>
                    </div>
                </>
            ) : (

                <div className="wrapper min-h-full flex flex-col items-center py-10">
                    <header className="header flex-shrink-0 text-center mb-10">
                        <Link href="/">
                            <a>
                                <Image
                                    src="/assets/images/the-top-drawer-logo-full.svg"
                                    alt="The Top Drawer Logo"
                                    width="266"
                                    height="68"
                                    className=""
                                    placeholder="blur"

                                    blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                                />
                            </a>
                        </Link>
                    </header>
                    <section className="content w-full flex flex-grow flex-col">
                        <div className="container mx-auto w-full flex flex-col items-center lg:px-0 px-5">
                            <div className="lg:w-1/3 w-full text-center bg-white border border-gray-300 p-8 rounded-xl">
                                <h3 className="mb-4">Logging In</h3>
                                <p className="text-gray-600 mb-4">Redirecting, please wait..</p>
                            </div>
                        </div>
                    </section>
                </div>

            )}
        </>
    )
}


// This function gets called at build time on server-side.
// It may be called again, on a serverless function, if
// revalidation is enabled and a new request comes in
export async function getStaticProps(context) {
    const config_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/configs')
    const config_data = await config_res.json()
    const config = config_data.data

    const menu_res = await fetch(process.env.NEXT_PUBLIC_API_URL + '/menu')
    const menu = await menu_res.json()

    return {
        props: {
            config: config,
            menu: menu,
        },
        // Next.js will attempt to re-generate the page:
        // - When a request comes in
        // - At most once every 10 seconds
        revalidate: 600, // In seconds

    }
}



export default LoginPage